import React, { useState } from "react";
import "../auth.css";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create the form data as a JSON object
        const data = {
            email: e.target.email.value,
        };

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data),
            redirect: "follow"
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/reset-password/`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                toast.success(`${responseData.message}`)
            } else {
                const errorText = await response.text();
                const errorData = JSON.parse(errorText);
              toast.error(`Error: ${errorData.error}`);
            }
        } catch (error) {
            toast.error(`Error submitting form:, ${error}`);
        }
    };

    return (
        <div className="formMain">
            <div className="formLink"><a href="login">Go back to login</a></div>
            <div className="formLink">Enter email address associated with your account to request password reset</div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="button-container">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
