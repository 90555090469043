import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProfile = () => {
    const { userId } = useParams();
    const [profileData, setProfileData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [author, setAuthor] = useState(null);

    useEffect(() => {
        if (profileData) {
            const { id, username, bio } = profileData;
            setAuthor({ id, username, bio });
        }
    }, [profileData]);

    const navigate = useNavigate();

    const handleNavigation = () => {
        if (author) {
            navigate('/admin_panel/createpost', { state: { author } });
        }
    };
    

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/profile/${userId}/`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setProfileData(data);
                } else {
                    const errorText = await response.text();
                    const errorData = JSON.parse(errorText);
                    toast.error(`Error: ${errorData.error}`);
                }
            } catch (error) {
                toast.error(`Error getting profile:, ${error}`);
            }
        };

        fetchProfile();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        // Send updated profile data to the server
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/profile/${userId}/`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
                body: JSON.stringify(profileData)
            });
    
            if (response.ok) {
                const data = await response.json();
                setProfileData(data);
                setIsEditing(false); // Exit edit mode after successful update
                toast.success(`${data.message}`)
            } else {
                const errorText = await response.text();
                const errorData = JSON.parse(errorText);
                toast.error(`Error: ${errorData.error}`);
            }
        } catch (error) {
            toast.error(`Error updating profile:, ${error}`);
        }
    };
    

    if (!profileData) {
        return <div className="bgLoader">
        <div className="loading loader"></div>
      </div>
    }

    return (
        <div className='profileMain'>
            <h1>User Profile</h1>
            <p><strong>ID:</strong> {profileData.id}</p>
            <p>
                <strong>Username:</strong>
                {isEditing ? (
                    <input
                        type="text"
                        name="username"
                        value={profileData.username}
                        onChange={handleChange}
                    />
                ) : (
                    profileData.username
                )}
            </p>
            <p>
                <strong>Email:</strong>
                {/* {isEditing ? (
                    <input
                        type="email"
                        name="email"
                        value={profileData.email}
                        // onChange={handleChange}
                    />
                ) : ( */}
                    {profileData.email}
                {/* )} */}
            </p>
            <p>
                <strong>First Name:</strong>
                {isEditing ? (
                    <input
                        type="text"
                        name="first_name"
                        value={profileData.first_name || ''}
                        onChange={handleChange}
                    />
                ) : (
                    profileData.first_name
                )}
            </p>
            <p>
                <strong>Last Name:</strong>
                {isEditing ? (
                    <input
                        type="text"
                        name="last_name"
                        value={profileData.last_name || ''}
                        onChange={handleChange}
                    />
                ) : (
                    profileData.last_name
                )}
            </p>
            <p>
                <strong>Bio:</strong>
                {isEditing ? (
                    <input
                        type="text"
                        name="bio"
                        value={profileData.bio || ''}
                        onChange={handleChange}
                    />
                ) : (
                    profileData.bio
                )}
            </p>
            <p>
                <strong>Location:</strong>
                {isEditing ? (
                    <input
                        type="text"
                        name="location"
                        value={profileData.location || ''}
                        onChange={handleChange}
                    />
                ) : (
                    profileData.location
                )}
            </p>
            <p><strong>Date of Birth:</strong> {new Date(profileData.date_of_birth).toLocaleDateString()}</p>
            <p><strong>Email Confirmed:</strong> {profileData.email_confirmed ? "Yes" : "No"}</p>
            <p><strong>Active:</strong> {profileData.is_active ? "Yes" : "No"}</p>
            <p><strong>Created At:</strong> {new Date(profileData.created_at).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(profileData.updated_at).toLocaleString()}</p>
            {isEditing ? (
                <div className="button-container">
                    <button onClick={handleUpdate}>Save</button>
                </div>
            ) : (
                <div className="button-container">
                    <button onClick={() => setIsEditing(true)}>Update Profile</button>
                </div>
            )}

            <div className="button-container">
                <button onClick={handleNavigation}>Create post</button>
            </div>
        </div>
    );
};

export default UserProfile;
