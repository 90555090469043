import React, { createContext, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const login = async (email, password) => {
        const data = { email, password };

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data),
            redirect: "follow"
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/login/`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                toast.success(`Login successful, redirecting to profile`);

                const userId = responseData.user.id;
                const author = {
                    id: responseData.user.id,
                    username: responseData.user.username,
                    bio: responseData.user.bio
                };

                console.log(responseData)

                // Store the access token and author object in localStorage
                localStorage.setItem('access_token', responseData.tokens.access_token);
                localStorage.setItem('author', JSON.stringify(author));

                navigate(`admin_panel/profile/${userId}`);
            } else {
                const errorText = await response.text();
                const errorData = JSON.parse(errorText);
                toast.error(`Error: ${errorData.error}`);
            }
        } catch (error) {
            toast.error(`Error submitting form:, ${error}`);
        }
    };

    return (
        <AuthContext.Provider value={{ login }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
