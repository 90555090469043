import React, { useEffect, useState } from "react";
import { useParams, Outlet, Link } from "react-router-dom";
import "./blog.css";

import blogHero from "../../assets/blogHero.webp";
import authorAvatar from "../../assets/icons8-avatar-64.png";
import Comments from "./postComments";

const SinglePost = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPost = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/posts/${postId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const postData = await response.json();
        setPost(postData);
        // console.log(postData)
        setLoading(false);
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        setError(`Error fetching post: ${errorData.error}`);
        setLoading(false);
      }
    } catch (error) {
      setError(`Error getting post: ${error}`);
      setLoading(false);
    }
  };

  
  useEffect(() => {
    getPost();
  }, []);

  if (loading) {
    return (
      <div className="bgLoader">
        <div className="loading loader"></div>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="singlePost">
      <div className="singlePostMain">
        <img
          className="image"
          src={process.env.REACT_APP_BASE_URL + post.image}
          alt={post.title}
        />
        <h1 className="title">{post.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
        <div>
          <Comments postId={postId} />
        </div>
      </div>
      <div className="singlePostAuthor">
        <img
          class="image"
          src={post.author.image || authorAvatar}
          alt={post.author.username}
        />
        <p>ABOUT THE AUTHOR</p>
        <h3>{post.author.username}</h3>
        <p>{post.author.bio}</p>
      </div>
    </div>
  );
};

export default SinglePost;
