import React from "react";
import "./footer.css";

import loc from "../../assets/location.svg";
import call from "../../assets/call.svg";
import mail from "../../assets/gmail.svg"
import sc1 from "../../assets/fb.svg";
import sc2 from "../../assets/ig.svg";
import sc3 from "../../assets/li.svg";
import sc4 from "../../assets/pi.svg";
import sc5 from "../../assets/wa.svg";
import sc6 from "../../assets/tw.svg";
import logo from "../../assets/dasce_logo.png"
import { Link } from "react-router-dom";

const Footer = ()=> {
    const currentYear = new Date().getFullYear();
    return(
        <>
            <div className="footer">
                <div className="footerTop">
                    <div className="ftOne">
                        <div className="ftLogo">
                            <Link to="/">
                            <img src={logo} alt="DASCE tech website logo"/>
                            <p className="textLogo">
                        DASCE  TECH AGENCY  </p>
                            </Link>
                        </div>
                        <p className="text footerP value">
                            We specialize in innovative web and mobile app development solutions, using cutting-edge technology to enhance user experience and drive business growth.
                            </p>
                            <div className="socials">
                                <img src={sc1} alt=""/>
                                <img src={sc2} alt=""/>
                                <img src={sc3} alt=""/>
                                <img src={sc4} alt=""/>
                                <img src={sc5} alt=""/>
                                <img src={sc6} alt=""/>
                            </div>
                    </div>
                    <div className="ftLinks">
                        <div className="ftHeader item">Quick links</div>
                        <div className="ftLink value"><Link to="/blog">Blog</Link></div>
                        <div className="ftLink value"><Link to="/about">About</Link></div>
                        <div className="ftLink value"><Link to="/services">Services</Link></div>
                        <div className="ftLink value"><Link to="/terms_conditions">Terms & Conditions</Link></div>
                        <div className="ftLink value"><Link to="/privacy_policy">Privacy Policy</Link></div>
                        <div className="ftLink value"><Link to="/disclaimer">Disclaimer</Link></div>
                        <div className="ftLink value"><Link to="/cookie_policy">Cookie Policy</Link></div>
                    </div>
                    <div className="ftContact">
                        <div className="ftHeader item">Contact us</div>
                        <div className="ccard">
                        <img src={loc} alt="Dasce tech location icon" />
                        <div>
                            <div className="item">office address</div>
                            <div className="value wc">25, Mfangano Str, 212, Nairobi, kenya</div>
                        </div>
                    </div>

                    <div className="ccard">
                        <img src={call} alt="Dasce tech call icon" />
                        <div>
                            <div className="item">call us</div>
                            <div className="value wc"><a href="tel:+254785993447">(+254) 742008666</a></div>
                        </div>
                    </div>

                    <div className="ccard">
                        <img src={mail} alt="Dasce tech mail icon" />
                        <div>
                            <div className="item">mail us</div>
                            <div className="value wc"><a href="mailto:dasceitsolutions@gmail.com">dasceitsolutions@gmail.com</a></div>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div className="divider"></div>
                
                <div className="cp">Copyright © {currentYear} | DASCE TECH Solutions. All Rights Reserved </div>
            </div>
        </>
    )
}

export default Footer;