import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../auth.css";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
    const [formData, setFormData] = useState({
        email: '',
        username: '',
        password: '',
        confirm_password: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Create the form data as a JSON object
      const data = {
          email: e.target.email.value,
          username: e.target.username.value,
          password: e.target.password.value,
          confirm_password: e.target.confirm_password.value
      };
  
      const requestOptions = {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: JSON.stringify(data),
          redirect: "follow"
      };
  
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/register/`, requestOptions);
  
          if (response.ok) {
              const responseData = await response.json();
              toast.success(`${responseData.message}`)
              navigate('/login');
          } else {
              const errorText = await response.text();
              const errorData = JSON.parse(errorText);
              toast.error(`Error: ${errorData.error}`);
          }
      } catch (error) {
          toast.error(`Error submitting form:, ${error}`);
      }
  };
  
    return (
        <div className="mainForm">
            <div className="formMain">
                <div className="formLink formTitle">Create your account</div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="confirm_password">Confirm Password</label>
                        <input
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="button-container">
                        <button type="submit">Submit</button>
                    </div>
                </form>
                <div className="formLink"><a href="login">Already registered? Login here</a></div>
            </div>
        </div>
    );
};

export default SignUp;
