import React from 'react';
import './privacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="legal-page">
      <h1>Privacy Policy</h1>
      <p>
        At DASCE Tech Solutions, we are committed to protecting your personal information and your right to privacy.
        This Privacy Policy describes how we collect, use, and share your information when you visit our website.
      </p>
      
      <h2>1. Important Information</h2>
      <p>This privacy policy aims to give you information on how our company collects and processes your personal data through your use of this website, including any data you may provide when you sign up for our services or contact us for inquiries.</p>
      
      <h2>2. Data We Collect</h2>
      <p>We may collect, use, store, and transfer different kinds of personal data, including your name, email address, and browsing behavior. We do not collect sensitive personal data unless explicitly consented to by the user.</p>
      
      <h2>3. How We Use Your Data</h2>
      <p>We will only use your personal data for legitimate business purposes, such as providing services to you, improving our website, and complying with legal requirements.</p>
      
      <h2>4. Data Security</h2>
      <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to access, correct, or erase your personal data. To exercise these rights, please contact us using the details provided on our website.</p>
    </div>
  );
}

export default PrivacyPolicy;
