import React, { useEffect, useState } from "react";
import "./service.css";
import { Helmet } from "react-helmet";
import ServiceCard from "./serviceCard";

const ServiceMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "DASCE Tech Agency | Services";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [services, setServicesData] = useState([]);

  const getServices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/services/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setServicesData(data);
        console.log(data);
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        console.error(`Error fetching services: ${errorData.error}`);
      }
    } catch (error) {
      console.error(`Error getting services: ${error}`);
    } finally {
      console.log(false); // Ensure loading is set to false after fetching
    }
  };

  useEffect(() => {
    getServices()
      .then()
      .catch((error) => {
        "error: "`Error getting services: ${error}`;
      });
  }, []);

  const canonicalUrl = "https://www.dasce.dev/services";

  return (
    <div className="sMain">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>DASCE Tech Agency | Services</title>
        <meta name="description" content="DASCE Tech Agency Services" />
        <meta
          name="keywords"
          content="web development, website development, web development solutions"
        />
      </Helmet>
      <div className="serviceHeaderContainer sectionMargin">
        {/* <img src={blogHero} alt="service-header-image" /> */}
        <div className="serviceText">
          <h1>Our Services</h1>
          <p>
            Unlock Your Business Potential with DASCE’s Cutting-Edge Digital
            Solutions At DASCE, we offer a comprehensive suite of web and mobile
            app development services designed to elevate your business in the
            digital world. Whether you're a startup looking to establish your
            online presence or an enterprise aiming to optimize your digital
            strategy, our expert team is here to help you achieve your goals.
          </p>
        </div>
      </div>
      <ServiceCard services={services} />
      <div className="serviceHeaderContainer">
        <div className="serviceText">
          <h1>Why choose us? </h1>
          At DASCE, we are committed to delivering excellence in every project
          we undertake. Our services are backed by years of experience, industry
          knowledge, and a passion for innovation. We work closely with you to
          understand your business needs and provide solutions that drive real
          results.
        </div>
      </div>
    </div>
  );
};

export default ServiceMain;
