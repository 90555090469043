import React from 'react';
import './disclaimer.css';

const Disclaimer = () => {
  return (
    <div className="legal-page">
      <h1>Disclaimer</h1>
      <p>
        The information provided by DASCE Tech Solutions (“we,” “us” or “our”) on dasce.dev is for general informational purposes only. 
        All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, 
        regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
      </p>
      <h2>1. External Links Disclaimer</h2>
      <p>
        The website may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties. Such external links are not investigated, monitored, or checked for accuracy or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site.
      </p>
      
      <h2>2. Professional Disclaimer</h2>
      <p>
        The site cannot and does not contain legal, financial, or technical advice. Any information provided is for general informational and educational purposes only and is not a substitute for professional advice.
      </p>

      <h2>3. Limitation of Liability</h2>
      <p>
        Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided. Your use of the site and reliance on any information on the site is solely at your own risk.
      </p>
      
      <h2>4. Changes to This Disclaimer</h2>
      <p>
        We reserve the right to amend this disclaimer at any time without prior notice. It is your responsibility to periodically review this disclaimer to stay informed about any updates.
      </p>
    </div>
  );
}

export default Disclaimer;
