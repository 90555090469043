import React from "react";
import feature1 from "../../assets/dasce_logo.png";
import "./about.css";

const About = () => {
    return(
        
        <div className="container">
            <div className="about">
                <img src={feature1} alt="logo" className="about-img responsive-img" />
                <div className="about--Content">
                    <h1>About Us</h1>
                    <p>Welcome to DASCE, Your Partner in Digital Transformation</p>
                    <p>At DASCE, we are passionate about crafting exceptional web and mobile app solutions that empower businesses to thrive 
                    in the digital world. With a focus on innovation, quality, and client satisfaction, we bring your ideas to life through 
                    state-of-the-art technology and creative design.</p>
                </div>
            </div>
            <div className="flex-container">
                <div className="flex-item">
                    <h2>Our Expertise</h2>
                    <p>Our team excels in web and mobile app development, design, and implementation. We have extensive experience in the digital landscape</p>
                </div>
                <div className="flex-item">
                    <h2>Our Mission</h2>
                    <p>To create exceptional solutions that drive innovation, increase efficiency, and improve customer satisfaction for our clients.</p>
                </div>
                <div className="flex-item">
                    <h2>Our Vision</h2>
                    <p>To become the leading provider of web and mobile app solutions, focused on innovation, quality, and client satisfaction.</p>
                </div>
                <div className="flex-item">
                    <h2>core Values</h2>
                    <ul>
                        <li>Innovation</li>
                        <li>Quality</li>
                        <li>Client Satisfaction</li>
                    </ul>
                </div>
                <div className="flex-item">
                    <h2>Our Team</h2>
                    <p>We have a diverse team of experts who work tirelessly to deliver exceptional solutions.</p>
                </div>
                
            </div>

            
        </div>
        
    )
}

export default About;