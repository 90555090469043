import React, { useState } from "react";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import "../auth.css";

const Comment = () => {
  const { postId } = useOutletContext();
  const [formData, setFormData] = useState({
    comment: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
  
    const content = formData.comment;
    const author = JSON.parse(localStorage.getItem('author'));
  
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('access_token')}`);
  
    const raw = JSON.stringify({ content, author });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/posts/${postId}/comments/`, requestOptions);
      const result = await response.text();
      console.log(result); 
      console.log(postId)
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  

  console.log('Comment component loaded'); // Debug log

  return (
    <div className="formMain">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="comment">Write your comment here</label>
          <input
            type="text"
            id="comment"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            required
          />
        </div>
        <div className="button-container">
          <button type="submit">Post comment</button>
        </div>
      </form>
    </div>
  );
};

export default Comment;

