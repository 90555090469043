import React, { useState } from "react";
import { useAuth } from "../../auth/authProvider"; 
import "../auth.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const { login } = useAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        login(formData.email, formData.password);
    };

    return (
        <div className="mainForm">
            <div className="formMain">
                <div className="formLink formTitle">Login to your account</div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="button-container">
                        <button type="submit">Submit</button>
                    </div>
                </form>
                <div className="formLink"><a href="forgotpassword">Forgot password?</a></div>
                <div className="formLink"><a href="signup">Don't have an account? Sign up</a></div>
            </div>
        </div>
    );
};

export default Login;
