import React, { useState, useEffect } from "react";
import "./home.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const [prevItem, setPrevItem] = useState({});
  const [nextItem, setNextItem] = useState({});

  const getProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/projects/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const projectsData = await response.json();
        setProjects(projectsData);
        setCurrentSlide(projectsData?.results[0]);
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        console.log(errorData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  // const nextSlide = () => {
  //   const item = getSlideContent(
  //     (prevSlide) => (prevSlide + 1) % projects?.results?.length
  //   );
  //   setCurrentSlide(item);
  // };

  // const prevSlide = () => {
  //   const item = getSlideContent(
  //     (prevSlide) =>
  //       (prevSlide - 1 + projects?.results?.length) % projects?.results?.length
  //   );
  //   setCurrentSlide(item);
  // };

  // const goToSlide = (index) => {
  //   setCurrentSlide(index);
  // };

  // const getSlideContent = (index) => {
  //   if (index > 2) index = 2;
  //   setCurrentIndex(index);
  //   const res = projects?.results || [];
  //   return res[index];
  // };

  useEffect(() => {
    setPrevItem(
      (currentIndex - 1 + projects?.results?.length) % projects?.results?.length
    );
    setNextItem((currentIndex + 1) % projects?.results?.length);
  }, [currentIndex, projects]);

  return (
    <div className="sliderMain">
      <div className="slider-container">
        <div className="before-slide">
          {Object.keys(nextItem).length === 0 && <span>Coming soon</span>}
          <a href={nextItem.link} target="_blank" rel="noopener noreferrer">
            <img src={prevItem.image} alt={prevItem.title} />
            <span>{prevItem.title}</span>
          </a>
        </div>
        <div className="slider">
          <a href={nextItem.link} target="_blank" rel="noopener noreferrer">
            <img src={currentSlide.image} alt={currentSlide.title} />
            <span>{currentSlide.title}</span>
          </a>
        </div>
        <div className="after-slide">
          {Object.keys(nextItem).length === 0 && <span>Coming soon</span>}
          <a href={nextItem.link} target="_blank" rel="noopener noreferrer">
            <img src={nextItem.image} alt={nextItem.title} />
            <span>{nextItem.title}</span>
          </a>
        </div>
        {/* <button className="prev" onClick={prevSlide}>
          ❮
        </button>
        <button className="next" onClick={nextSlide}>
          ❯
        </button> */}
        {/* <div className="nav-dots">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`nav-dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div> */}
      </div>
      <div className="nav-dots">
        {/* {projects?.results?.map((_, index) => (
          <div
            key={index}
            className={`nav-dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))} */}
      </div>
    </div>
  );
};

export default Slider;
