import React from "react";

import "./service.css";

function ServiceCard({ services }) {
  const data = services["results"] || []

  return (
    <>
      {data.length > 0 && data.map((service) => (
        <div className="serviceCard sectionMargin">
          <div className="serviceCardImage">
            <img src={service.image} alt="service-image" />
          </div>
          <div className="serviceCardContent">
            <h3 class="serviceCardTitle">{service.title}</h3>
            <p class="serviceCardDescription" dangerouslySetInnerHTML={{ __html: service.description }}/>
          </div>
        </div>
      ))}
      {data.length === 0 && <h3>No services</h3>}
    </>
  );
}

export default ServiceCard;
