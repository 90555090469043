import React from 'react';
import './termsConditions.css';

const TermsConditions = () => {
  return (
    <div className="legal-page">
      <h1>Terms & Conditions</h1>
      <p>
        These terms and conditions outline the rules and regulations for the use of DASCE's website.
        By accessing this website we assume you accept these terms and conditions in full.
      </p>
      <h2>1. Introduction</h2>
      <p>By using our website, you accept these terms and conditions in full. If you disagree with these terms, you must not use our website.</p>
      
      <h2>2. Intellectual Property</h2>
      <p>Unless otherwise stated, we or our licensors own the intellectual property rights of the website and material on the website. All intellectual property rights are reserved.</p>
      
      <h2>3. Use of Website</h2>
      <p>You are permitted to use the website for lawful purposes only. You must not use our website in any way that causes, or may cause, damage to the website or impairment of its availability.</p>
      
      <h2>4. Liability Limitation</h2>
      <p>We will not be liable for any loss or damage of any nature arising out of the use of our website or services.</p>

      <h2>5. Governing Law</h2>
      <p>These terms and conditions will be governed by and construed in accordance with the laws of our jurisdiction, and any disputes relating to these terms will be subject to the exclusive jurisdiction of the courts in our region.</p>
    </div>
  );
}

export default TermsConditions;
