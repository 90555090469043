import React from "react";
import "./pnf.css";

const PageNotFound = () =>{
    return (
        <>
        <div className="pnfMain">
            {/* <img src={pnf} alt="Dasce tech page not found background"/> */}
            <div className="img"></div>
            <div className="pnf">
                <h1>404</h1>
                <h2>page not found</h2>
                <h3>sorry, we can't find the page that you're looking for</h3>
                <div className="btn"><a href="/">go back home</a></div>
            </div>
        </div>
        </>
    )
}

export default PageNotFound;