import React from "react";
import { Outlet } from "react-router-dom";

const AdminPanel = () => {
    return(
        <div className="adminMain">
            <div className="header">Welcome to the admin panel, here you can create and login to your account and perform other account related actions</div>
            <div className="header"><a href="/admin_panel/login">Login</a> to continue</div>
            <Outlet />
        </div>
    )
}

export default AdminPanel;