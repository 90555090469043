import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blog.css";
import { Helmet } from "react-helmet";

import imagg from "../../assets/background.jpeg";
import authorAvatar from "../../assets/icons8-avatar-64.png";
import PaginatedPosts from "../blog/paginatedPosts"

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const getLatestPosts = async (query) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/posts/`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const posts = await response.json();
        setLatestData(posts?.results?.slice(0, 3));
      } else {
        const errorText = await response.text();
        console.error(`Error fetching posts: ${errorText}`);
      }
    } catch (error) {
      console.error(`Error getting posts: ${error}`);
    }
  };

  const getPosts = async (query) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/posts/`;
      if (query !== "" && query !== undefined) {
        url += `?query=${query}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const posts = await response.json();
        setBlogData(posts);
        // console.log(posts)
      } else {
        const errorText = await response.text();
        console.error(`Error fetching posts: ${errorText}`);
      }
    } catch (error) {
      console.error(`Error getting posts: ${error}`);
    } finally {
      setLoading(false); // Ensure loading is set to false after fetching
    }
  };

  const getCategories = async (query) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/categories/`;
      if (query !== "" && query !== undefined) {
        url += `?query=${query}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const categories = await response.json();
        setCategoryData(categories);
        // console.log(posts)
      } else {
        const errorText = await response.text();
        console.error(`Error fetching posts: ${errorText}`);
      }
    } catch (error) {
      console.error(`Error getting posts: ${error}`);
    } finally {
      setLoading(false); // Ensure loading is set to false after fetching
    }
  };

  useEffect(() => {
    getPosts();
    getCategories();
    getLatestPosts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === blogData.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change slide every 3 seconds

    setCurrentIndex(0);
    return () => clearInterval(interval);
  }, [blogData.length]);

  function handleChange(event) {
    setSearchVal(event.target.value);
  }

  function handleSearchClick() {
    setLoading(true); // Ensure loading is set to false after fetching
    getPosts(searchVal);
  }

  // Function to calculate the word count of a post
  const calculateWordCount = (text) => {
    const strippedText = text.replace(/<[^>]*>/g, ""); // Remove HTML tags
    const words = strippedText.trim().split(/\s+/).length; // Split by whitespace and count words
    return words;
  };

  // Function to estimate read time based on word count
  const calculateReadTime = (wordCount) => {
    const wordsPerMinute = 200; // Average reading speed
    const readTime = Math.ceil(wordCount / wordsPerMinute); // Round up to nearest minute
    return readTime;
  };

  const canonicalUrl = "https://www.dasce.dev/blog";

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>DASCE Tech Agency | Blog</title>
        <meta name="description" content="DASCE Tech Blog" />
        <meta
          name="keywords"
          content="tech blog, website development, web development solutions"
        />
      </Helmet>
      <div className="main">
        {/* <div className="carousel">
          {blogData?.results?.slice(0, 3).map((post, index) => (
            <div
              key={post.id}
              className={`carousel-item ${
                index === currentIndex ? "active" : ""
              }`}
            >
              <Link key={post.id} to={`/post/${post.id}`}>
                <img
                  className="blogThumb"
                  src={post.image || imagg}
                  alt={post.title || "Image"}
                />
                <div className="bInfo">
                  <h2 className="title">{post.title}</h2>
                  <p
                    className="content"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  ></p>
                </div>
              </Link>
            </div>
          ))}
        </div> */}
        <div class="mainBody">
          <div className="blogSection">
            <div className="searchSection">
              <input
                type="text"
                className="searchInput"
                value={searchVal}
                onChange={handleChange}
                placeholder="Search..."
              />
              <button className="searchButton" onClick={handleSearchClick}>
                &#128269;
              </button>
            </div>
            {blogData.length < 1 && (
              <>
                <h2 className="notFound"> No posts found</h2>
              </>
            )}

            {loading ? (
              // Display loading div if data is being fetched
              <div className="bgLoader">
                <div className="loading loader"></div>
              </div>
            ) : (
              <>
                {" "}
                <PaginatedPosts blogData={blogData} />
              </>
            )}
          </div>

          <div className="side">
            <div>
              <h1 className="hpost">Latest posts</h1>
              {latestData.map((post) => (
                <div className="hpostContent">
                  <img
                    className="hpostImg"
                    src={post.image || imagg}
                    alt={post.title || "Image"}
                  />
                  <Link key={post.id} to={`/post/${post.id}`}>
                    <div className="hpostInfo">

                      <h2 className="title">{post.title}</h2>
                      <p
                        className="content"
                        dangerouslySetInnerHTML={{ __html: post.content }}
                      ></p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div>
              <h1 className="hpost">Categories</h1>
              <span className="separator"></span>
              {categoryData?.results?.map((category) => (
                <Link key={category.id} to={`/category/${category.id}`}>
                  <h3>{category.name}</h3>
                  <div className="separator"></div>
                </Link>
              ))}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
