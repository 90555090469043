import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./actions.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Editor } from '@tinymce/tinymce-react';

const CreatePost = () => {
    const location = useLocation();
    const { author } = location.state || {};

    if (author) {
        console.log(author);
    }

    const [formData, setFormData] = useState({
        postTitle: '',
        postContent: '',
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const editorContent = editorRef.current.getContent();
        setFormData((prevState) => ({
            ...prevState,
            postContent: editorContent
        }));

        // Create the form data as a JSON object
        const data = {
            title: formData.postTitle,
            content: editorContent,
            author: {
                id: author.id,
                username: author.username,
                bio: author.bio
            }
        };

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            body: JSON.stringify(data),
            redirect: "follow"
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/posts/`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                toast.success(`Post created successfully!`);

                console.log(responseData)
            } else {
                const errorText = await response.text();
                const errorData = JSON.parse(errorText);
                toast.error(`Error: ${errorData.error}`);
            }
        } catch (error) {
            toast.error(`Error submitting form: ${error}`);
        }
    };

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
        }
    };

    const tinymceAPI = process.env.REACT_APP_TINYMCE_API

    return (
        <div className="home">
            <div className="postEditor">
                <h3>Create new post</h3>
                <form onSubmit={handleSubmit} className="editorForm">
                    <div>
                        <label htmlFor="postTitle">Post title</label>
                        <input
                            type="text"
                            id="postTitle"
                            name="postTitle"
                            value={formData.postTitle}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {/* <div>
                        <label htmlFor="postContent">Post content</label>
                        <textarea
                            id="postContent"
                            name="postContent"
                            value={formData.postContent}
                            onChange={handleChange}
                            required
                        />
                    </div> */}
                             <Editor
        apiKey= {tinymceAPI}
        onInit={(_evt, editor) => editorRef.current = editor}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
                    <div className="button-container">
                        <button type="submit">Publish</button>
                    </div>
                </form>
      {/* <button onClick={log}>Log editor content</button> */}
            </div>
        </div>
    );
}

export default CreatePost;
