import React, { useEffect, useState } from "react";

import "./comment.css";

const Comments = ({ postId }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [commentData, setComments] = useState([]);
  const [error, setError] = useState(null);
  console.log(commentData);

  const getPostComments = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/posts/${postId}/comments/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const comments = await response.json();
        setComments(comments);
        // console.log(postData)
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        setError(`Error fetching comments: ${errorData.error}`);
      }
    } catch (error) {
      setError(`Error getting comments: ${error}`);
    }
  };

  const addComment = async (event) => {
    event.preventDefault();
    const { name, email, content } = event.target;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/posts/${postId}/comments/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: name.value,
            email: email.value,
            content: content.value,
          }),
        }
      );

      if (response.ok) {
        getPostComments();
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        setError(`Error adding comment: ${errorData.error}`);
      }
    } catch (error) {
      setError(`Error adding comment: ${error}`);
    }
  };

  useEffect(() => {
    getPostComments();
  }, [postId]);

  return (
    <div className="comments">
      <div class="comments-section">
        <h3>Comments</h3>
        {commentData &&
          commentData?.results?.map((comment, index) => (
            <div class="comment">
              <div class="comment-header">
                <span class="comment-author">{comment.name}</span>
                <span class="comment-date">{comment.created_at}</span>
              </div>
              <p class="comment-text">{comment.content}</p>
            </div>
          ))}
        {commentData?.results?.length === 0 && <h3>No comments</h3>}
      </div>

      <div class="comment-form-container">
        <h2>Leave a Comment</h2>
        <form onSubmit={addComment} class="comment-form">
          <div className="d-flex">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
          </div>
          <div class="form-group">
            <label for="comment">Comment</label>
            <textarea id="comment" name="content" rows="4" required></textarea>
          </div>
          <button type="submit" class="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Comments;
