import React from 'react';
import './cookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className="legal-page">
      <h1>Cookie Policy</h1>
      <p>
        This Cookie Policy explains what cookies are, how DASCE Tech Solutions uses cookies, and how you can manage them.
        By continuing to use our website, you consent to the use of cookies in accordance with this policy.
      </p>
      <p>
        <strong>What are cookies?</strong> Cookies are small text files that are placed on your device to help the
        site provide a better user experience. Cookies help us remember your preferences and improve our website.
      </p>
      <p>
        <strong>How do we use cookies?</strong> We use cookies to enhance your experience on our site, including for
        session management and personalization. We also use cookies to analyze how our site is used and to provide
        targeted advertising.
      </p>
      <p>
        <strong>How can I manage cookies?</strong> Most web browsers allow you to control cookies through their settings.
        You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you
        do not accept cookies, you may not be able to use some features of our website.
      </p>
      <p>
        <strong>Changes to this Cookie Policy</strong> We may update our Cookie Policy from time to time. Any changes
        will be posted on this page, and we encourage you to review this policy periodically.
      </p>
    </div>
  );
}

export default CookiePolicy;
