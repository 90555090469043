import React, { useState } from "react";
import "./nav.css"
import logo from "../../assets/dasce_logo.png";
import search from "../../assets/search.png";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
        setIsOpen(!isOpen)
    };

    const handleLogoClick = () => {
        setIsOpen(false); 
        setClicked(false);
    }
    return (
        <>
            <div className="nav">
                <Link to="/" onClick={handleLogoClick}>
                <div className="logo">
                    <img src={logo} alt=""/>
                </div>
                </Link>
                <div className="menuToggle" onClick={handleClick}>
      <div className={`bar ${clicked ? 'clicked' : ''}`}></div>
      <div className={`bar ${clicked ? 'clicked' : ''}`}></div>
      <div className={`bar ${clicked ? 'clicked' : ''}`}></div></div>
                <div className={`navlinks ${isOpen && "open"}`}>
                    <NavLink to="/" activeClassName="active" className="navlink" onClick ={handleClick}>Home</NavLink>
                    <NavLink to="/blog" activeClassName="active" className="navlink" onClick ={handleClick}>Blog</NavLink>
                    <NavLink to="/about" activeClassName="active" className="navlink" onClick ={handleClick}>About</NavLink>
                    {/* <NavLink to="/success" activeClassName="active" className="navlink" onClick ={handleClick}>Projects</NavLink> */}
                    <NavLink to="/services" activeClassName="active" className="navlink" onClick ={handleClick}>Services</NavLink>
                </div>
                {/* <div className="search">
                    <img src={search} alt="dasce search icon" />
                </div> */}
            </div>
        </>
    )
}

export default Navbar;