import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';

import Navbar from './components/navBar/navBar';
import Homepage from './components/homePage/homePage';
import Footer from './components/footer/footer';
import Blog from './components/blog/blog';

import Disclaimer from './components/disclaimer/disclaimer';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import TermsConditions from './components/terms/termsConditions';
import CookiePolicy from './components/cookiePolicy/cookiePolicy';
import About from './components/about/aboutPage';

import SignUp from './components/signUp/signUp';
import Login from './components/login/login';
import UserProfile from './components/userProfile/userProfile';
import ResetPassword from './components/forgotPassword/forgotPassword';
import CreatePost from './components/postActions/createPost';
import SinglePost from './components/blog/singlePost';
import Comment from './components/postActions/postComment';
import HomePge2 from './components/homePage/homePage2';
import AdminPanel from './components/adminPanel/adminPanel';
// import About from './components/about/about';
import ServiceMain from './components/services/serviceMain';
import PageNotFound from './components/pageNotFound/pageNotFound';

function App() {

  const location = useLocation();
  const hideNavbarAndFooter = location.pathname === '/login' || location.pathname === '/signup' || location.pathname.includes('/admin_panel') || location.pathname.includes('/*');

  return (
    <>
      {!hideNavbarAndFooter && <Navbar/>}
        <Routes>
          <Route path="/" element={<HomePge2/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_conditions" element={<TermsConditions />} />
          <Route path="/cookie_policy" element={<CookiePolicy/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/admin_panel" element={<AdminPanel/>}>
            <Route path="signup" element={<SignUp/>} />
            <Route path="login" element={<Login/>} />
            <Route path="profile/:userId" element={<UserProfile />} />
            <Route path="forgotpassword" element={<ResetPassword/>} />
            <Route path="createpost" element={<CreatePost/>} />
          </Route>
          <Route path="/post/:postId" element={<SinglePost />} >
            <Route path="comment" element={<Comment/>}/>
          </Route>
          <Route path="/about" element={<About/>}/>
          <Route path="/services" element={<ServiceMain/>}/>
          <Route path="/*" element={<PageNotFound/>} />
        </Routes>
      {!hideNavbarAndFooter && <Footer/>}
      <ToastContainer/>
      <SpeedInsights/>
      <Analytics/>
    </>
  );
}

export default App;
