import React, { useRef, useEffect, useState } from "react";
import "./home.css";
import Slider from "./slider";
import { Helmet } from "react-helmet";

import logo from "../../assets/dasce_logo.png";
import bg1 from "../../assets/pexels-shkrabaanthony-5475752.jpg";
import bg2 from "../../assets/bg2.png";
import s1 from "../../assets/development.png";
import s2 from "../../assets/mobile-development.png";
import s3 from "../../assets/develop.png";
import s4 from "../../assets/bullhorn.png";
// import stat1 from "../../assets/team.svg";
// import stat2 from "../../assets/customers.svg";
// import stat3 from "../../assets/projects.svg";
// import stat4 from "../../assets/awards.svg";
import bullet from "../../assets/Vector.svg";
import tbg from "../../assets/ai.png";
import chaves from "../../assets/chaves.jpg";
import quote from "../../assets/quote.svg";
import loc from "../../assets/location.svg";
import call from "../../assets/call.svg";
import mail from "../../assets/gmail.svg";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

const HomePge2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    message: "",
  });

  const [imageHeight, setImageHeight] = useState(0);
  const imgRef = useRef(null);
  const dynamicDivRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);

  const updateImageHeight = () => {
    if (imgRef.current) {
      if (imgRef.current.style.display === "none") {
        setImageHeight("500px");
      } else {
        setImageHeight(`${imgRef.current.clientHeight}px`);
      }
    }
  };

  useEffect(() => {
    // Store the current image element in a variable
    const currentImgRef = imgRef.current;

    // Update the height when the image loads
    currentImgRef?.addEventListener("load", updateImageHeight);

    // Update the height initially
    updateImageHeight();

    // Cleanup event listener on component unmount
    return () => {
      currentImgRef?.removeEventListener("load", updateImageHeight);
    };
  }, []);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      updateImageHeight();
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const data = formData;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/contact-us/`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(`${responseData.message}`);
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        toast.error(`Error : ${errorData.error}`);
      }
    } catch (error) {
      toast.error(`Error submitting form: ${error}`);
    }
  };

  const getTestimonials = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/testimonials/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const testimonialData = await response.json();
        setTestimonials(testimonialData);
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        console.log(errorData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  const handleClick = () => {
    const element = document.querySelector(".contact");
    if (element) {
      const offset = 50;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1,
  //     );
  //     clearInterval(interval);
  //   }, 5000); // Change slide every 5 seconds

  //   console.log(interval)
  //   return () => clearInterval(interval);
  // }, [testimonials.length]);
  useEffect(() => {
    if (testimonials?.results?.length <= 1) return; // Exit early if there's one or no testimonials
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        // If at the last index, return 0 (first item), otherwise increment
        return prevIndex === testimonials?.results?.length - 1
          ? 0
          : prevIndex + 1;
      });
    }, 5000); // Change slide every 5 seconds

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [testimonials]);

  const canonicalUrl = "https://www.dasce.dev/";

  return (
    <div className="homeB">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>DASCE Tech Agency | Home</title>
        <meta
          name="description"
          content="DASCE Tech Agency - For innovative web development solutions"
        />
        <meta
          name="keywords"
          content="web development, website development, web development solutions"
        />
      </Helmet>
      <div className="hero">
        <div className="htext">
          <h1 className="accent">Welcome to dasce</h1>
          <h2 className="headerA">
            We are <span className="acc">dasce</span> tech agency
          </h2>
          <div className="divider"></div>
          <h3 className="text">
            <b>DASCE</b> specializes in innovative web and mobile app
            development solutions, using cutting-edge technology to enhance user
            experience and drive business growth. Partner with us to turn your
            vision into reality.
          </h3>
          <div className="btn" onClick={handleClick}>
            get in touch
          </div>
        </div>
        <div className="himage">
          <img src={bg1} alt="Dasce tech primary landing page image" />
        </div>
      </div>

      <div className="about">
        <div className="aboutA">
          <img src={bg2} alt="Dasce tech about section image" />
        </div>
        <div className="aboutB">
          <h1 className="accent">About us</h1>
          <h2 className="headerB">
            we bring <span className="acc">creative</span> ideas to life
          </h2>
          <div className="divider"></div>
          <h3 className="text">
            At DASCE, we are dedicated to crafting exceptional web and mobile
            app solutions that empower businesses to thrive in the digital
            world. We focus on innovation, quality, and client satisfaction,
            bringing ideas to life through state-of-the-art technology and
            creative design. Choose DASCE for innovative solutions, a
            client-centric approach, an expert team, and comprehensive services,
            as we build the future one digital solution at a time.{" "}
          </h3>
          <div className="btn">
            <a href="/about">read more</a>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="servicesA">
          <h1 className="accent">our services</h1>
          <h2 className="headerB">
            experience the power of <span className="acc">innovation</span>
          </h2>
          <div className="divider"></div>
          <h3 className="text">
            At DASCE, we offer a comprehensive suite of web and mobile app
            development services designed to elevate your business in the
            digital world. Whether you're a startup looking to establish your
            online presence or an enterprise aiming to optimize your digital
            strategy, our expert team is here to help you achieve your goals.
          </h3>
          <div className="btn">
            <a href="/services">view all</a>
          </div>
        </div>
        <div className="servicesB">
          <div className="service sAlt">
            <img src={s1} alt="Dasce tech web development image" />
            <h5 className="title">Web Development</h5>
            <h6 className="desc">
              Our web development services are tailored to create visually
              stunning, high-performance websites that deliver a seamless user
              experience{" "}
            </h6>
          </div>

          <div className="service sAlt">
            <img src={s2} alt="Dasce tech mobile app development image" />
            <h5 className="title">Mobile App Development</h5>
            <h6 className="desc">
              We design and develop powerful mobile apps that engage users and
              drive business growth{" "}
            </h6>
          </div>

          <div className="service sAlt">
            <img src={s3} alt="Dasce tech custom software development image" />
            <h5 className="title">Custom Software Development</h5>
            <h6 className="desc">
              We provide custom software development services to solve your
              specific business challenges, ensuring you have the tools you need
              to succeed
            </h6>
          </div>

          <div className="service sAlt">
            <img src={s4} alt="Dasce tech digital marketing image" />
            <h5 className="title">Digital Marketing</h5>
            <h6 className="desc">
              Our digital marketing services are designed to enhance your online
              presence and drive targeted traffic to your business
            </h6>
          </div>
        </div>
      </div>

      {/* <div className="stats">
                <div className="stat">
                    <img src={stat1} alt="" />
                    <div className="sValue">50+</div>
                    <div className="sName">Team members</div>
                </div>

                <div className="stat">
                    <img src={stat2} alt="" />
                    <div className="sValue">500+</div>
                    <div className="sName">Hapy customers</div>
                </div>

                <div className="stat">
                    <img src={stat4} alt="" />
                    <div className="sValue">75+</div>
                    <div className="sName">Award Winnings</div>
                </div>

                <div className="stat lStat">
                    <img src={stat3} alt="" />
                    <div className="sValue">200+</div>
                    <div className="sName">Completed Projects</div>
                </div>
            </div> */}

      <div className="projects">
        <h2 className="headerB">
          enjoy our <span className="acc">latest</span>
          <br /> projects
        </h2>
        <div className="Hsec">
          <div className="divider"></div>
          <div className="accent">our work</div>
          <div className="divider"></div>
        </div>
        <div>
          <Slider />
        </div>
      </div>

      <div className="exp">
        <div className="exp1">
          <h1 className="accent">why us</h1>
          <div className="divider"></div>
          <h2 className="headerB">
            1 year of <span className="acc">experience</span> as a creative
            agency
          </h2>
          <div className="btn">
            <a href="/about">read more</a>
          </div>
        </div>

        <div className="exp2">
          <h3 className="text">
            Through quality work, we have forged long-standing relations with
            past clients, our strength lies in:
          </h3>
          <div className="bullet">
            <img src={bullet} alt="Dasce tech bullet point image" />
            <div className="text">customer satisfaction</div>
          </div>
          <div className="bullet">
            <img src={bullet} alt="Dasce tech bullet point image" />
            <div className="text">experienced team</div>
          </div>
          <div className="bullet">
            <img src={bullet} alt="Dasce tech bullet point image" />
            <div className="text">modern technology</div>
          </div>
        </div>
      </div>

      <div className="testimonials">
        {/* <div className="tbg"><img src={tbg} alt=""/></div> */}
        <div className="tbg-wrapper">
          <div className="tbg">
            <img
              src={tbg}
              alt="Dasce tech testimonials background image"
              ref={imgRef}
            />
          </div>
          <div className="tbg">
            <img src={tbg} alt="Dasce tech testimonials background image" />
          </div>
        </div>
        <div
          className="testi"
          ref={dynamicDivRef}
          style={{ height: imageHeight }}
        >
          <h2 className="headerB">
            what <span className="acc">our</span>
            <br /> clients say?
          </h2>
          <div className="divider"></div>
          <div className="accent">testimonials</div>
          {/* <div className="tcard">
                    
                        <div className="text">DASCE is the best design agency out there,  would recommend 100% </div>
                        <div className="client">
                            <div className="cimage"><img src={chaves} alt=""/></div>
                            
                            <div>
                                <div className="cname">carol chaves</div>
                                <div className="corg">tnt productions</div>
                            </div>
                            <div className="group">
                                <img src={quote} alt=""/>
                                <img src={quote} alt=""/>
                            </div>
                        </div>
                    </div> */}
          <div className="carousel">
            {testimonials?.results?.map((client, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
              >
                <div
                  className="text ct"
                  dangerouslySetInnerHTML={{ __html: client.summary }}
                ></div>
                <div className="client">
                  <div className="cimage">
                    <img src={client.image} alt={client.client_name} />
                  </div>
                  <div>
                    <div className="cname">{client.client_name}</div>
                    <div className="corg">{client.company}</div>
                  </div>
                  <div className="group">
                    <img src={quote} alt="Dasce tech testimonial quote image" />
                    <img src={quote} alt="Dasce tech testimonial quote image" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="btn">view all testimonials</div> */}
        </div>
        {/* <div className="tbg"><img src={tbg} alt=""/></div> */}
      </div>

      <div className="contact">
        <div className="contactA">
          <h2 className="headerB">
            get in <span className="acc">touch</span>
          </h2>
          <div className="divider"></div>
          <h3 className="text">
            Got a project idea? Want to optimize your website and drive more
            sales? Contact us
          </h3>
          <div className="ccard">
            <img src={loc} alt="Dasce tech location icon" />
            <div>
              <div className="item">office address</div>
              <div className="value">25, Mfangano Str, 212, Nairobi, kenya</div>
            </div>
          </div>

          <div className="ccard">
            <img src={call} alt="Dasce tech call icon" />
            <div>
              <div className="item">call us</div>
              <div className="value">
                <a href="tel:+254785993447">(+254) 742008666</a>
              </div>
            </div>
          </div>

          <div className="ccard">
            <img src={mail} alt="Dasce tech mail icon" />
            <div>
              <div className="item">mail us</div>
              <div className="value">
                <a href="mailto:dasceitsolutions@gmail.com">
                  dasceitsolutions@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="contactB">
          <div className="form">
            <form className="formm" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="full_name">Your name</label>
                <input
                  className="formInput"
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Your email</label>
                <input
                  className="formInput"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="message">Your message</label>
                <textarea
                  className="formInput"
                  type="text"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="button-container">
                <button type="submit">send message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePge2;
